body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.styles_navbar__sTuFB {
	width: 100%;
	height: 70px;
	background-color: #3bb19b;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.styles_navbar__sTuFB h1 {
	color: white;
	font-size: 25px;
	margin-left: 20px;
}

.styles_white_btn__t7Jhc {
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: white;
	border-radius: 20px;
	width: 120px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
	margin-right: 20px;
}

.styles_signup_container__3uIAy {
	width: 100%;
	min-height: 100vh;
	background-color: #f5f5f5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.styles_signup_form_container__1wlMW {
	width: 900px;
	height: 500px;
	display: flex;
	border-radius: 10px;
	box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
		0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.styles_left__Os8Ax {
	flex: 1 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #3bb19b;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.styles_left__Os8Ax h1 {
	margin-top: 0;
	color: white;
	font-size: 35px;
	align-self: center;
}

.styles_right__2AWbo {
	flex: 2 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.styles_form_container__329UK {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.styles_form_container__329UK h1 {
	font-size: 40px;
	margin-top: 0;
}

.styles_input__30ig2 {
	outline: none;
	border: none;
	width: 370px;
	padding: 15px;
	border-radius: 10px;
	background-color: #edf5f3;
	margin: 5px 0;
	font-size: 14px;
}

.styles_error_msg__3KVzF {
	width: 370px;
	padding: 15px;
	margin: 5px 0;
	font-size: 14px;
	background-color: #f34646;
	color: white;
	border-radius: 5px;
	text-align: center;
}

.styles_white_btn__2vVVr,
.styles_green_btn__2CLkO {
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: white;
	border-radius: 20px;
	width: 180px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
}

.styles_green_btn__2CLkO {
	background-color: #3bb19b;
	color: white;
	margin: 10px;
}

.styles_login_container__11I78 {
	width: 100%;
	min-height: 100vh;
	background-color: #f5f5f5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.styles_login_form_container__38a3R {
	width: 900px;
	height: 500px;
	display: flex;
	border-radius: 10px;
	box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
		0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.styles_left__QQzRe {
	flex: 2 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.styles_form_container__2FMYA {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.styles_form_container__2FMYA h1 {
	font-size: 40px;
	margin-top: 0;
}

.styles_input__Ar1yG {
	outline: none;
	border: none;
	width: 370px;
	padding: 15px;
	border-radius: 10px;
	background-color: #edf5f3;
	margin: 5px 0;
	font-size: 14px;
}

.styles_error_msg__2Oaor {
	width: 370px;
	padding: 15px;
	margin: 5px 0;
	font-size: 14px;
	background-color: #f34646;
	color: white;
	border-radius: 5px;
	text-align: center;
}

.styles_right__303i3 {
	flex: 1 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #3bb19b;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.styles_right__303i3 h1 {
	margin-top: 0;
	color: white;
	font-size: 40px;
	align-self: center;
}

.styles_white_btn__3S3CW,
.styles_green_btn__21q_0 {
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: white;
	border-radius: 20px;
	width: 180px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
}

.styles_green_btn__21q_0 {
	background-color: #3bb19b;
	color: white;
	margin: 10px;
}

